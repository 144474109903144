<template>
	<v-tab v-on="$listeners">
		{{ $t('team_manager.tabs_title.matrix_accountant_company') }}
		<v-icon>group_work</v-icon>
	</v-tab>
</template>

<script>
export default {
	name: 'CustomerAssignmentTab'
}
</script>
